import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/3JVi4GpACEU"
    bibleGroupSrc="https://pdfhost.io/v/TH~xOuU2E_Microsoft_Word_Bible_Group_Homework_411docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="A Joyful Start - The Pursuit of Happiness" />
  </Layout>
)

export default SermonPost
